.audio-infos {
  padding: 5px;
  margin: 10px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.audio-image {
  height: auto;
}

.trans {
  display: flex;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  height: 350px;
  width: 320px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  background: linear-gradient(50deg, #833ab4 25%, #d400d4 64%, #ee82ee 100%);
  scroll-behavior: smooth;
}
.pod_slide_image {
  width: 100%;
  height: 300px;
  border-radius: 50%;
  border: 2px solid #f2f2f2;
  margin: auto;
}

.track_data {
  font-size: 14px;
  margin-top: 60px;
  padding-left: 50px;
  padding-right: 50px;
}
.title {
  font-size: 16px;
}
.controls-wrapper {
  border-radius: 10px;
  margin: 2px;
  padding: 2px;
  justify-content: space-between;
}
.controls {
  justify-content: space-between;
}

.control_btn {
  color: #833ab4;
  background-color: #f2f2f2;
}
.control_btn:hover {
  color: #ffffff;
  background-color: #833ab4;
}

.volume {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #833ab4;
}

.progress {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  background-color: #f2f2f2;
}
.time {
  padding: 5px;
  color: #666666;
}

/* Input range - chrome and safari */

input[type='range'] {
  --range-progress: 0;
  --webkit-appearance: none;
  position: relative;
  background: #ffffff;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
input[type='range']::-moz-range-track {
  position: relative;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type='range']::before {
  content: '';
  height: 3px;
  background: #833ab4;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

/* played progress length - firefox */
input[type='range']::-moz-range-progress {
  background: #833ab4;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px;
}

/* slider thumb - chrome and safari */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  background-color: #833ab4;
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
input[type='range']:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* slider thumb - firefox */
input[type='range']::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #833ab4;
  cursor: pointer;
  border: transparent;
  position: relative;
}
/* dragging thumb - firefox */
input[type='range']:active::-moz-range-thumb {
  transform: scale(1.2);
}

@media (min-width: 320px) and (max-width: 760px) {
  .audio-infos {
    padding: auto;
    margin: auto;
    flex-wrap: wrap;
  }

  .audio-image {
    height: auto;
    margin-top: 1px;
  }
  .track_data {
    margin-top: 1px;
    padding: 1.5px;
    font-size: 10px;
  }
  .trans {
    display: none;
  }
 
  .controls {
   display: flex;
    margin: 0%;
  }

  .control_btn {
    color: #833ab4;
    background-color: #f2f2f2;
  }

  .volume {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #833ab4;
  }

  .progress {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    background-color: #f2f2f2;
  }
  .time {
    padding: 5px;
  }

  .controls-wrapper {
    margin: 1px;
    padding: 1px;
    align-items: flex-start;
  }
}
