@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;500;600;800&family=Signika:wght@300;400;500&display=swap');

.pagination .page-link {
  color: #833ab4;
}

.space {
  background-color: #ffffff;
  /* margin-top: 4px; */
  margin-bottom: 4px;
  border-radius: 50px;
  padding: 5px;
  display: flex;
}
.Search_field {
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  outline: none;
  border: #833ab4;
  font-size: 14px;
}
.btn_Search {
  background-color: #f2f2f2;
  color: #833ab4;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  border-radius: 10px;
  outline: none;
  border: #833ab4;
  margin-right: 10px;
}
.btn_Search:hover {
  color: #ffffff;
  background-color: #833ab4;
}

.trend_card_slider .pod_slide {
  background-color: #f2f2f2;
  color: #833ab4;
}
.trend_Hot {
  font-size: 18px;
  color: #833ab4;
  width: auto;
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: 10px;
}
.Trend_bar {
  justify-content: flex-start;
  display: inline-flex;
  font-size: 14px;
}
.Search_bar {
  justify-content: flex-end;
}

.btn_search {
  color: #833ab4;
}

.pod_slide_action {
  padding: 1px 1px;
}

.pod_panels {
  color: #833ab4;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background: #f2f2f2;
  border-radius: 10px;
  padding-bottom: 3px;
  margin-bottom: 1cm;
  cursor: pointer;
  transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
}
.pod_panels:hover {
  transform: scale(+1.1); /* Increase the size on hover */
}

.pod_panels .file_image {
  width: auto;
  height: 5cm;
  border-radius: 10px;
  padding: 1px;
}
.artist_container {
  margin-top: 40%;
  margin-bottom: 10%;
  justify-content: flex-end;
}
.artist_name {
  color: #000;
  margin: 6px;
  justify-items: center;
}
.data_title {
  margin: 6px;
  font-size: 10px;
  justify-content: start;
}
.file_play {
  border-color: #833ab4;
}
.file_play:hover {
  border-color: #833ab4;
}

/* action button */
.action_tab_container {
  position: absolute;
  bottom: 5px;
  margin-top: 10px;
  width: auto;
  margin-bottom: 5px;
}

.action_tab {
  display: flex;
  border-radius: 10px;
  padding: 20px;
  background: rgba(131, 58, 180, 1);
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 320px;
}
.action_tab_container .action_tab .action_button {
  padding-right: 20px;
  padding-left: 20px;
  color: #c0bebe;
  font-size: 18px;
  transition: transform 0.5s ease-in-out; /* Add a smooth transition effect */
}
.action_tab_container .action_tab .action_button :hover {
  color: #ffffff;
  transform: scale(1); /* Increase the size on hover */
}

@media (min-width: 320px) and (max-width: 760px) {
  .trend_Hot {
    font-size: 8px;
    border-radius: 10px;
  }
  .Trend_bar {
    font-size: 10px;
  }

  .Search_field {
    font-size: 10px;
  }
  /* ----------------------- */

  .pod_slide_image:first-child {
    width: 6cm;
    height: auto;
    padding: auto;
  }
  .pod_slide {
    height:max-content;
    position:absolute;
  }
  
.action_tab_container {
display: absolute;
  bottom: 5px;
  margin-top: 10px;
  width: auto;
  margin-bottom: 5px;
}

.pod_panels {
  width: 100%;
  height: auto;
  padding: 1px;
  cursor: pointer;
  transition: none;
}
.pod_panels:hover {
  transform: none;
}
.artist_container {
    margin-top: 5px;
    margin-bottom: 5px;
}
.pod_panels .file_image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 1px;
}
  
.action_tab_container {
position: relative;
  bottom: 5px;
  margin-top: 10px;
  width: auto;
  margin-bottom: 10px;
}
}
