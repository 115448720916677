#otp input {
  display: block;
}
#otp {
  display: block;
  align-items: center;
}

.otp_text {
  font-family: 'Sora', sans-serif;
  font-weight: 900;
  text-align: center;
}

.update_password_panel {
  display:flex;
  margin-top:50px;
  justify-content:center;
}
.update_password_intro{
  margin: 10px;
  text-align: center;
}