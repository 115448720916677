@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;500;600;800&family=Signika:wght@300;400;500&display=swap');

.footer {
  background-image: url(/src/assets/footer.png);
  background-size: cover;
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
}

.footer_zip {
  padding-top: 12rem;
}
a.socials.nav-link {
  font-size: 25px;
  color: #ffffff;
}
a.socials.nav-link:hover {
  color: #c5c2c2;
}

.quick_links{
  color: #ffffff;
  font-size: 14px;
  font-family: 'Karla', sans-serif;
  font-family: 'Signika', sans-serif;
  font-weight:500;
  text-decoration: none;
}
.quick_links:hover {
  color: #cfcece;
}
/* --------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 760px) {
  .footer {
    background-image: url(/src/assets/home.png);
    background-size: cover;
    height: 300px;
    width: 100%;
    margin: 3px;
    background-repeat: no-repeat;
  }
  .footer_zip {
    padding-top: 10rem;
  }
 

  
a.socials.nav-link {
  font-size: 15px;
  color: #ffffff;
}
a.socials.nav-link:hover {
  color: #ebebeb;
}

.quick_links{
  color: #ffffff;
  font-size: 12px;
  font-weight:800;
  text-decoration: none;
}
.quick_links:hover {
  color: #cfcece;
}
img.brand_logo{
  display: none;
}
}
