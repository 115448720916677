.about_background {
  height: 13cm;
  width: 100%;
  font-size: large;
  font-weight: 800;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  background: linear-gradient(149deg, #833ab4 25%, #d400d4 64%, #ee82ee 100%);
}

.text_panel {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 10px;
}
.about_text {
  margin: 1rem;
}
