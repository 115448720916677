@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;500;600;800&family=Signika:wght@300;400;500&display=swap');

.nav_section .link {
  font-family: 'Karla', sans-serif;
  font-family: 'Signika', sans-serif;
  color: black;
  font-weight: bold;
  font-weight: 400;
  font-size: 14px;
}
.nav_section .link:hover {
  color: #833ab4;
}
.btn_login {
  background-color: #833ab4;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.btn_sign_up {
  border-color: #833ab4;
  color: #833ab4;
  font-size: 14px;
  font-weight: 400;
}

.btn_login:hover {
  background-color: #833ab4;
  color: #ffffff;
}
.btn_sign_up:hover {
  background-color: #833ab4;
  color: #ffffff;
}
.signout_panel {
  background-color: #f2f2f2;
  display: flex;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0px 2px 2px rgba(207, 205, 205);
}
.signout_panel .text {
  margin: auto;
  padding: 2px;
  font-size: 12px;
}
