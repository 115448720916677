#left_view {
  color: rgb(0, 0, 0);
  border-radius: 5px;
  margin: 10px 10px;
  padding: 1rem;
  height: 5.5cm;
  background: #e6e6e6;
}

.trend_live p {
  border-radius: 10px;
  /* global 94%+ browsers support */
  background: linear-gradient(149deg, #833ab4 25%, #d400d4 64%, #ee82ee 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.trend_live .trend_1 {
  text-decoration: none;
  color: black;
  display: inline-table;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px #833ab4 solid;
  padding: 4px;
  margin: 2px;
  font-size: 10px;
}

.steam_View {
  background-color: #ffffff;
  height: 10cm;
  border-radius: 5px;
  margin: 10px 10px;
  padding: 1rem;
}
.media_bar {
  position: sticky;
  background: #e6e6e6;
  height: 11.5cm;
  color: #833ab4;
  margin: 0.5rem ;
  padding:0.1rem;
  border-radius: 5px;
  display:inline-block;
}

.media_button {
  color: #833ab4;
  padding: 1rem;
  margin: 1rem;
 
}

.swiper {
  width: auto;
  height: 14cm;

}

.swiper-slide {
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
}
@media (min-width: 320px) and (max-width: 760px) {

  .swiper{
    width:auto;
  }
}

