@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;500;600;800&family=Signika:wght@300;400;500&display=swap');


.banner_layout {
  margin-top: 3rem;
  margin-bottom:10.5rem; 
}

.banner_intro {
  font-size: 60px;
  font-family: 'Karla', sans-serif;
  font-family: 'Signika', sans-serif;
  font-weight: 800;
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    149deg,
    #833ab4 25%,
    #d400d4 64%,
    #ee82ee 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    149deg,
    #833ab4 25%,
    #d400d4 64%,
    #ee82ee 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    149deg,
    #833ab4 25%,
    #d400d4 64%,
    #ee82ee 100%
  );

  /* ie 10+ */
  background: -ms-linear-gradient(
    149deg,
    #833ab4 25%,
    #d400d4 64%,
    #ee82ee 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(149deg, #833ab4 25%, #d400d4 64%, #ee82ee 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.banner_text {
  font-weight: 800;
  font-size: 30px;
  font-family: 'Karla', sans-serif;
  font-family: 'Signika', sans-serif;
  margin-top: 25px;
  padding-bottom: 15px;
  color: #2f2e41;
}

.btn_more {
  margin-top: 1rem;
  margin-bottom: 30px;
  font-size: large;
  font-weight: 800;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(149deg, #833ab4 25%, #d400d4 64%, #ee82ee 100%);
}

/* product */
* {
  font-family: 'Karla', sans-serif;
font-family: 'Signika', sans-serif;
}

.product_brand {
  background-color: #f2f2f2;
  height: fit-content;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.product_margin {
  padding-bottom: 6rem;
}

.product_text {
  font-size: 50px;
  font-weight: 800;
}

.product_text .qoute {
  display: flex;
  padding-bottom: 0;
  color: #833ab4;
}
.product_text div {
  background: linear-gradient(149deg, #833ab4 25%, #d400d4 64%, #ee82ee 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.micro_text {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: auto;
 line-height:1.7rem;
  color: #000;
  font-family: 'Karla', sans-serif;
  font-family: 'Signika', sans-serif;
}

.product_img:first-child {
  width: 10cm;
  height: 10cm;
  border: #833ab4 1px solid;
  border-radius: 100px;
  transition: all 0.5s;
  align-content: center;
}

.product_img:first-child:hover {
  transform: scale(1.1);
  border: #833ab4 1.8px solid;
}

.why_thought {
  padding-top: 5rem;
}

/* ---------------------------------------------------- */

@media (min-width: 320px) and (max-width: 760px) {
  .product_img:first-child {
    width: 7cm;
    height: 7cm;
  }
  .product_text {
    font-size: 35px;
  }
  .product_brand {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .product_img_2{
margin-top: 2rem;
  }

}

/* ----------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 760px) {


  .banner_layout {
    margin-top: 1rem;
  }
  .banner_intro {
    font-size: 50px;
    font-family: 'Karla', sans-serif;
    font-family: 'Signika', sans-serif;
    font-weight: 200;
  }
  .banner_text {
    font-weight: 800;
    font-size: 20px;
    font-family: 'Karla', sans-serif;
    font-family: 'Signika', sans-serif;
    margin-top: 20px;
    padding-bottom: 10px;
    color: #2f2e41;
  }

}
