.layout_panel {
  column-gap: normal;
  margin-top: 2rem;
  margin-bottom: 0;

}
.login_img {
  max-width: 80%;
  height: 100%;
}
.form_panel {
  background-color: #e6e6e6;
  border-radius: 10px;
  color: #000000;
  margin-left: 15px;
  margin-right: 15px;
  padding: 2rem;
  width: 400px;
}

.intro {
  text-align: center;
  font-size: 15px;
  margin: 15px;
  font-weight: 700;
  font-family: 'Sora', sans-serif;
  color: #000000;
  margin-bottom: auto;
}

.login {
  text-align: center;
  font-size: 12px;
  margin-bottom: 15px;
  margin-left: 5px;
  font-family: 'Sora', sans-serif;
  color: #000000;
  display: inline-flex;
}
.signUp_link {
  color: #833ab4;
  padding-left: 5px;
}

.remember_me {
  font-size: 12px;
  font-family: 'Sora', sans-serif;
  color: #000000;
  display: inline-flex;
}
.remember_me div {
  margin-left: 5px;
}
.btn_login_page {
  background-color: #833ab4;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}

.thrid_party{
  text-align: center;
}
.third_container {
    margin: auto;
  justify-content: center;
  align-items:center;
  display: flex;
}
.third_party_icons {
  margin-left: 10px;
  margin-right: 10px;
  padding: auto;
}
.third_party_icons Buttoon{
    border-radius: none;
}
.spotiy_icon{
  color: #44f144;
}
.facebook_icon{
  color: #2d0db8;
}


.reset_password {
  text-align: right;
  font-size: 12px;
  margin: 10px;
  font-family: 'Sora', sans-serif;
  color: #000000;
  display: inline-flex;
}
.reset_password_link {
  color: #833ab4;
  padding-left: 5px;
}

.login_icons {
  color: #833ab4;
}

.alert_card{
margin:5px;
display:flexbox;
color:red;

}
/* ---------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 760px) {
  .layout_panel {
    column-gap: normal;
    margin-top: 1rem;
  }

  .form_panel {
    background-color: #e6e6e6;
    border-radius: 10px;
    width: auto;
    color: #000000;
    margin-left: 15px;
    margin-right: 15px;
    padding: 2rem;
  }
  .Login_section .login_img{
    display:none;
  }


}
