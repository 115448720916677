.layout_panel_sign {
  column-gap: normal;
  margin-top: 3rem;
}

.form_panel_sign {
  background-color: #e6e6e6;
  border-radius: 10px;
  color: #000000;
  margin-left: 15px;
  margin-right: 15px;
  padding: 2rem;
  width: 400px;
}

.Sign {
  text-align: center;
  font-size: 12px;
  margin-bottom: 15px;
  margin-left: 5px;
  font-family: 'Sora', sans-serif;
  color: #000000;
  display: inline-flex;
}
.Sign_link {
  color: #833ab4;
  padding-left: 5px;
}

.intro_sign {
  text-align: center;
  font-size: 15px;
  margin: 15px;
  font-weight: 700;
  font-family: 'Sora', sans-serif;
  color: #000000;
  margin-bottom: auto;
}
.intro_text_sign {
  font-size: 10px;
  color: #000000;
  font-weight: 700;
  font-family: 'Sora', sans-serif;
  margin-bottom: 20px;
  font-style: italic;
  text-align: center;
}

.terms_cons div {
  font-size: 12px;
  font-family: 'Sora', sans-serif;
  color: #000000;
  display: inline-flex;
}
.terms_cons_link {
  color: #833ab4;
  padding-left: 5px;
  display: inline;
}

.btn_Sign_page {
  background-color: #833ab4;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
}
.signUp_link {
  color: #833ab4;
  padding-left: 5px;
}

.sign_icons {
  color: #833ab4;
}

/* ---------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 760px) {
  .layout_panel_sign {
    column-gap: normal;
    margin-top: 1rem;
  }

  .form_panel_sign {
    background-color: #e6e6e6;
    border-radius: 10px;
    width: auto;
    color: #000000;
    margin-left: 15px;
    margin-right: 15px;
    padding: 2rem;
  }

  .Sign_section .signin_slide {
    display: none;
  }
}
